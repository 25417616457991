import { useLocation } from "react-router-dom";

// Custom hook to check if the current page matches specific segments
const usePageCheck = () => {
  const location = useLocation(); // Access the current location
  const pathname = location.pathname; // Get the pathname from the location
  const segments = pathname.split("/").filter(Boolean); // Split the pathname into segments and remove empty strings

  // Check if specific segments exist in the current URL
  const isPersonalitySnapshotPage = segments.includes("personality-snapshot-builder");
  const isCoverLetterPage = segments.includes("cover-letters-builder");
  const isResumePage = segments.includes("builder");

  return {
    isPersonalitySnapshotPage,
    isCoverLetterPage,
    isResumePage,
    segments, // Return segments if needed for other checks
  };
};

export default usePageCheck;
