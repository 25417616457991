/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import { AssessmentDto } from "@reactive-resume/dto";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect } from "react";

import { ASSESSMENTS_KEY } from "@/client/constants/query-keys"; // Create a constant for assessments key
import { axios } from "@/client/libs/axios";
import usePageCheck from "@/client/pages/builder/hooks/check-page";
import { useResumeStore } from "@/client/stores/resume";

import { AssessmentTypes } from "./constants/main";
import { useBase64 } from "./fetch-image";

/**
 * Custom hook to fetch and manage active assessments.
 * Also it checks and sets what page is to store and changes default "My motivation" to "My personal summary"
 *
 * This hook retrieves assessments from the API, specifically looking for DISC and Work Values assessments.
 * It also sets values in the resume store based on the fetched data and the current page context.
 *
 * @returns {Object} An object containing the following properties:
 * @returns {AssessmentDto[] | undefined} assessments - The array of fetched assessments or undefined if loading or an error occurred.
 * @returns {AssessmentDto | undefined} discAssessment - The fetched DISC assessment or undefined if not found.
 * @returns {AssessmentDto | undefined} workValuesAssessment - The fetched Work Values assessment or undefined if not found.
 * @returns {boolean} isLoading - A boolean indicating if the assessments are currently being loaded.
 * @returns {Error | null} error - An error object if the query fails, otherwise null.
 *
 * @example
 * const { assessments, discAssessment, workValuesAssessment, isLoading, error } = useActiveAssessments();
 *
 * if (isLoading) {
 *   return <p>Loading...</p>;
 * }
 *
 * if (error) {
 *   return <p>Error loading assessments.</p>;
 * }
 */

// Fetch all assessments from the API
export const fetchAssessments = async () => {
  const response = await axios.get<AssessmentDto[], AxiosResponse<AssessmentDto[]>>(
    `/assessment/active`,
  );
  return response.data;
};

// Custom hook to use assessments
export const useActiveAssessments = () => {
  const setValue = useResumeStore((state) => state.setValue);
  const { isCoverLetterPage, isPersonalitySnapshotPage, isResumePage } = usePageCheck();
  const {
    error,
    isLoading,
    data: assessments,
  } = useQuery({
    queryKey: [ASSESSMENTS_KEY],
    queryFn: fetchAssessments,
    // retryOnMount: true,
  });

  // Find the assessments for DISC and Work Values
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const discAssessment = assessments?.find((a) => a.instrumentId === AssessmentTypes.DISC);
  const workValuesAssessment = assessments?.find(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    (a) => a.instrumentId === AssessmentTypes.WORK_VALUES,
  );

  const { imgSrc: workValueImg, isLoading: imgWVLoading } = useBase64(
    workValuesAssessment?.reportAccessCodeStandard1_svg,
  );
  const { imgSrc: discImg, isLoading: imgDiscLoading } = useBase64(
    discAssessment?.reportAccessCodeStandard1_svg,
  );

  // Use useEffect to set values only after assessments are loaded
  useEffect(() => {
    if (isLoading) return;
    const discUpdatedAt = discAssessment?.updatedAt
      ? new Date(discAssessment.updatedAt)
      : undefined;
    const workValuesUpdatedAt = workValuesAssessment?.updatedAt
      ? new Date(workValuesAssessment.updatedAt)
      : undefined;

    if (discUpdatedAt && workValuesUpdatedAt) {
      const newestDate =
        discUpdatedAt.getTime() > workValuesUpdatedAt.getTime()
          ? discUpdatedAt
          : workValuesUpdatedAt;

      const day = String(newestDate.getDate()).padStart(2, "0");
      const month = String(newestDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const year = String(newestDate.getFullYear()).slice(-2); // Last two digits of the year

      const formattedDate = `${month}/${day}/${year}`;
      setValue("basics.testDate", formattedDate);
      console.log(formattedDate);
    }

    setValue("sections.summary.discToken", discAssessment?.reportAccessCodeStandard1_svg);
    setValue(
      "sections.summary.workValueToken",
      workValuesAssessment?.reportAccessCodeStandard1_svg,
    );
    setValue("sections.summary.isPersonalitySnapshotPage", isPersonalitySnapshotPage);
    setValue("sections.summary.isCoverLetterPage", isCoverLetterPage);
    setValue("sections.summary.isResumePage", isResumePage);
    setValue("sections.summary.workValueImg", workValueImg);
    setValue("sections.summary.discImg", discImg);
    if (isPersonalitySnapshotPage) {
      setValue("sections.summary.name", t`My personal summary`);
    }
    // setValue("sections.summary.apiKey", apiKey);
    // setValue("sections.summary.apiAssesKey", apiAssesKey);
  }, [
    isLoading,
    assessments,
    discAssessment,
    workValuesAssessment,
    imgDiscLoading,
    imgWVLoading,
    setValue,
  ]);

  return { assessments, discAssessment, workValuesAssessment, isLoading, error };
};
