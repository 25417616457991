import { Trans } from "@lingui/macro";
// import { FacebookLogo, GithubLogo, TwitterLogo } from "@phosphor-icons/react";

const Footer = () => {
  return (
    <footer className="bg-white p-6 text-gray-800 sm:p-8">
      <div className="ml-10 grid grid-cols-2 gap-8 md:grid-cols-4">
        <div>
          {/* <h3 className="text-lg font-bold uppercase sm:text-xl">
            <Trans>COMPANY</Trans>
          </h3> */}
          <ul className="mt-4 space-y-2 text-sm sm:text-base">
            <li>
              <a href="/about-us">
                <Trans>About</Trans>
              </a>
            </li>
            {/* <li>
              <Trans>Blog</Trans>
            </li>
            <li>
              <Trans>Affiliate Program</Trans>
            </li> */}
          </ul>
        </div>
        <div>
          {/* <h3 className="text-lg font-bold uppercase sm:text-xl">
            <Trans>Help and support</Trans>
          </h3> */}
          <ul className="mt-4 space-y-2 text-sm sm:text-base">
            <li>
              <a href="/about-us">
                <Trans>Contact Us</Trans>
              </a>
            </li>
            {/* <li>
              <Trans>Knowledge Center</Trans>
            </li>
            <li>
              <Trans>Sponsorships</Trans>
            </li> */}
          </ul>
        </div>
        <div>
          {/* <h3 className="text-lg font-bold uppercase sm:text-xl">
            <Trans>LEARNING</Trans>
          </h3> */}
          <ul className="mt-4 space-y-2 text-sm sm:text-base">
            <li>
              <a
                href="/privacy-policy
"
              >
                <Trans>Privacy Policy</Trans>
              </a>
            </li>
            {/* <li>
              <Trans>Manuals</Trans>
            </li>
            <li>
              <Trans>Tutorials</Trans>
            </li>
            <li>
              <Trans>Communities</Trans>
            </li> */}
          </ul>
        </div>
        <div>
          {/* <h3 className="text-lg font-bold uppercase sm:text-xl">
            <Trans>LEGAL</Trans>
          </h3> */}
          <ul className="mt-4 space-y-2 text-sm sm:text-base">
            {/* <li>
              <Trans>Privacy Policy</Trans>
            </li> */}
            <li>
              <a href="/terms-and-conditions">
                <Trans>Terms & Conditions</Trans>
              </a>
            </li>
            {/* <li>
              <Trans>Third-Party Tools</Trans>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="mb-10"></div>

      {/* Centering the logo */}
      <div className="flex justify-center">
        <a href="/">
          <img src="/landing/workinglife-logo.svg" alt="WorkingLife Logo" className="w-[150px]" />
        </a>
      </div>
      <p className="mt-8 text-center text-sm text-gray-600 sm:text-base">
        <Trans>&copy; 2024, workinglife Inc. All rights reserved.</Trans>
      </p>

      {/* <div className="mt-4 flex justify-center space-x-4">
        <a href="https://github.com/your-profile" target="_blank" rel="noopener noreferrer">
          <GithubLogo
            size={20}
            className="text-gray-800 transition-colors duration-200 hover:text-blue-600"
          />
        </a>
        <a href="https://twitter.com/your-profile" target="_blank" rel="noopener noreferrer">
          <TwitterLogo
            size={20}
            className="text-gray-800 transition-colors duration-200 hover:text-blue-400"
          />
        </a>
        <a href="https://facebook.com/your-profile" target="_blank" rel="noopener noreferrer">
          <FacebookLogo
            size={20}
            className="text-gray-800 transition-colors duration-200 hover:text-blue-700"
          />
        </a>
      </div> */}
    </footer>
  );
};

export default Footer;
