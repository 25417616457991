/* eslint-disable lingui/no-unlocalized-strings */
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

const testimonials = [
  {
    date: "May 8, 2024",
    name: "Siobhan Fitzpatrick",
    position: "Fleet Manager",
    title: "Pleasantly surprised",
    text: "I was asked to add a Personality Snapshot as part of the job application process—and I was pleasantly surprised! It's refreshing to see a focus on me as a person right from the start. The assessments were easy and insightful and I could add extra remarks too.",
    image: "/landing/testim-1.png",
  },
  {
    date: "Nov 21, 2024",
    name: "Olivier DeGusta",
    position: "Senior Hiring Manager",
    title: "Valuable tool",
    text: "As a hiring manager, I get flooded with AI-generated resumes and cover letters, so I really appreciate that WorkingLife encourages more personalized, authentic application documents. The Personality Snapshots are especially innovative in this regard. We now invite all applicants to create one—not mandatory, of course, but we find that serious candidates usually take the opportunity. It's become a valuable tool for identifying those who are truly interested.",
    image: "/landing/testim-2.png",
  },
];

const TestimonialsSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="relative min-h-[620px] bg-[#F9FAFB] px-4 sm:px-8">
      <img
        src="/landing/testimonials-icons.png" // Adjust the path accordingly
        alt="Testimonials Background"
        className="absolute inset-0 w-full object-cover"
      />
      <div className="relative mx-auto max-w-4xl pt-12 sm:pt-20">
        <h2 className="relative z-10 mb-6 text-center text-2xl font-bold text-[#0182A9] sm:text-3xl">
          <Trans>What Our Clients Say About Us</Trans>
        </h2>
        <div className="relative overflow-hidden rounded-lg">
          <div
            className="mt-4 flex transition-transform duration-300"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {testimonials.map((testimonial, index) => (
              <div key={index} className="min-w-full p-4 sm:max-w-sm">
                <div className="flex flex-col items-center rounded-lg bg-white shadow-md sm:flex-row">
                  <img
                    src={testimonial.image}
                    alt={`Client ${index + 1}`}
                    className="w-2/3 rounded-t-lg sm:w-1/4 sm:rounded-l-md sm:rounded-t-none"
                  />
                  <div className="p-4 sm:p-5 sm:pl-8">
                    <p className="mt-2 text-xs text-gray-500">{testimonial.date}</p>
                    <h3 className="mt-2 text-lg font-semibold text-[#3F444F] sm:text-xl">
                      {testimonial.title}
                    </h3>
                    <p className="mt-2 text-sm text-gray-700">{testimonial.text}</p>
                    <p className="mt-6 text-right text-xs">
                      <span className="font-bold">{testimonial.name}</span> /{" "}
                      <span className="text-[#3F444F]">{testimonial.position}</span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Navigation Arrows */}
        <div className="absolute bottom-[-2] z-10 flex w-full items-center justify-between px-4 sm:inset-x-0 sm:px-0">
          <button className="cursor-pointer p-2" onClick={prevSlide}>
            <img src="/landing/chevron-down.svg" alt="Previous" />
          </button>
          <button className="cursor-pointer p-2" onClick={nextSlide}>
            <img src="/landing/chevron-down.svg" alt="Next" className="rotate-180" />
          </button>
        </div>
        {/* Dots Navigation */}
        <div className="z-10 mt-6 flex justify-center">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`mx-1 size-2 rounded-full ${
                currentIndex === index ? "bg-[#0182A9]" : "bg-gray-300"
              }`}
              onClick={() => {
                setCurrentIndex(index);
              }}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSlider;
