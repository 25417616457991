import React from "react";
import { useNavigate } from "react-router-dom";

// Define the props interface
type ButtonProps = {
  border?: boolean;
  children: React.ReactNode;
  bgColor?: string;
  textColor?: string;
  onClick?: () => void;
  borderColor?: string;
  navigateTo?: string; // New prop to specify the navigation path
  sectionId?: string; // New prop to specify the section to scroll to
};

const Button: React.FC<ButtonProps> = ({
  border,
  children,
  bgColor = "bg-transparent",
  textColor = "text-black",
  borderColor = "border-[#3F444F]",
  onClick,
  navigateTo = "/dashboard/resumes",
  sectionId,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (navigateTo && !sectionId) {
      navigate(navigateTo);
    }
    if (sectionId) {
      // Scroll to the specific section with the given ID
      // eslint-disable-next-line unicorn/prefer-query-selector
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={`min-w-fit rounded ${border ? "border" : ""} ${bgColor} ${borderColor} px-4 py-2 ${textColor} max-md:min-w-72`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
