import { Trans } from "@lingui/macro";
import { useMemo } from "react";
import { matchRoutes, Outlet, useLocation } from "react-router-dom";

import { useAuthProviders } from "@/client/services/auth/providers";

import { SocialAuth } from "./_components/social-auth";
import useRouteImage from "./hooks/use-route-image";

const authRoutes = [{ path: "/auth/login" }, { path: "/auth/register" }];

export const AuthLayout = () => {
  const location = useLocation();
  const { providers } = useAuthProviders();
  const isAuthRoute = useMemo(() => matchRoutes(authRoutes, location) !== null, [location]);
  const imgSrc = useRouteImage();

  if (!providers) return null;

  const hideDivider = !providers.includes("email") || providers.length === 1;

  return (
    <div className="flex h-screen w-screen bg-white">
      {/* Image on the left, occupying half the screen */}
      <div className="relative hidden lg:block lg:w-1/2">
        {" "}
        {/* Set to lg:w-1/2 */}
        <img
          width={1920}
          height={1080}
          alt="Auth"
          className="size-full object-cover object-center"
          src={imgSrc}
        />
      </div>

      {/* Content on the right, occupying the other half */}
      <div className="relative flex w-full flex-col justify-center gap-y-8 px-12 sm:mx-auto sm:basis-[420px] sm:px-0 lg:w-1/2">
        {" "}
        <Outlet />
        {isAuthRoute && (
          <>
            {!hideDivider && (
              <div className="relative  flex items-center justify-center">
                <img src="/assets/grey-line.svg" alt="Divider line" className="w-full" />
                <span className="absolute bg-white px-1 text-xs text-[#D0D0D0]">
                  <Trans>or</Trans>
                </span>
              </div>
            )}

            <SocialAuth />
          </>
        )}
        <div className="mb-4 flex justify-center">
          <a href="/">
            <img src="/landing/workinglife-logo.svg" alt="WorkingLife Logo" className="w-[150px]" />
          </a>
        </div>
      </div>
    </div>
  );
};
