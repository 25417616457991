import { createZodDto } from "nestjs-zod/dto";
import { z } from "nestjs-zod/z";

// Define the schema for the assessment creation
export const createAssessmentSchema = z.object({
  instrumentId: z.string().min(1),
  id: z.string().min(1),
  reportAccessCodeStandard1_svg: z.string().min(1),
  apiId: z.string().min(1),
  updatedAt: z.string().min(1),
  isActive: z.string().min(1),
  status: z.string().min(1),
});

// Create the DTO class by combining the schema
export class AssessmentDto extends createZodDto(createAssessmentSchema) {}
