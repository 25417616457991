import { AssessmentDto } from "@reactive-resume/dto";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ASSESSMENTS_KEY } from "@/client/constants/query-keys"; // Create a constant for assessments key
import { axios } from "@/client/libs/axios";

// Fetch all assessments from the API
export const fetchAssessments = async () => {
  const response = await axios.get<AssessmentDto[], AxiosResponse<AssessmentDto[]>>(`/assessment`);
  return response.data;
};

// Custom hook to use assessments
export const useAssessments = () => {
  const {
    error,
    isLoading,
    data: assessments,
  } = useQuery({
    queryKey: [ASSESSMENTS_KEY],
    queryFn: fetchAssessments,
    // You can set enabled to false to prevent the query from running automatically
  });

  return { assessments, isLoading, error };
};
