import { useLocation } from "react-router-dom";

export enum ResumeTypeEnum {
  "cover-letters" = "COVER_LETTER",
  resumes = "RESUME",
  "personality-snapshots" = "PERSONALITY_SNAPSHOT",
  assessment = "ASSESSMENT",
}

// Custom Hook to check the last segment of the URL
export const useDashboardOpenTab = () => {
  const location = useLocation(); // Access current location

  const pathname = location.pathname;

  const segments = pathname.split("/");

  const lastSegment = segments.at(-1) as keyof typeof ResumeTypeEnum; // Cast to a valid enum key

  if (lastSegment in ResumeTypeEnum) {
    return ResumeTypeEnum[lastSegment]; // Return the enum value
  }

  return null;
};
