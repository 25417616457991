import { Trans } from "@lingui/macro";
import React from "react";

import Button from "./buttons/button-kit";

const HeaderSection = () => {
  return (
    <div className=" bg-white ">
      <header className="mb-10 ml-3 grid grid-cols-1 bg-white p-8 text-left md:ml-0 md:grid-cols-2">
        {/* Left content: Text and buttons */}
        <div className="flex flex-col justify-center md:ml-24">
          <h1 className="text-3xl font-black text-[#111928] md:text-6xl">
            <Trans>
              Free Forever{" "}
              <span className="bg-[#DB7B03] bg-clip-text text-transparent">Career Tools</span>{" "}
            </Trans>
          </h1>
          <p className="mt-4 text-lg text-[#3F444F]">
            <Trans>Standing with you from graduation to retirement!</Trans>
          </p>
          <p className="mt-4 text-lg text-[#3F444F]">
            <Trans>
              Use Personality Snapshots to make your job application truly authentic. It's highly
              appreciated by hiring managers as it shows human effort and genuine interest.
            </Trans>
          </p>
          <p className="mt-4 text-lg text-[#3F444F]">
            <Trans>
              Increase the quality of your working life and that of job applications specifically!
            </Trans>
          </p>
          <div className="mt-6 space-y-4 md:space-x-4">
            <Button bgColor="bg-[#0182A9]" textColor="text-white" navigateTo="/auth/register">
              <Trans>Sign Up</Trans>
            </Button>
            <Button border borderColor="#E5E7EB" sectionId="faq">
              <Trans>FAQ</Trans>
            </Button>
          </div>
        </div>
        {/* Right content: Image */}
        <div className="mt-8 items-center justify-center md:block lg:flex">
          <iframe
            className="max-w-sm border-none"
            width="448"
            height="450"
            src="https://www.youtube.com/embed/UTr0VUuWlS4?si=dsvQRgX09PHwU7Fl"
            title="YouTube video player"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </div>
        <div className="min-h-10"></div>
      </header>
    </div>
  );
};

export default HeaderSection;
