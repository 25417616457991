import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "@lingui/macro";
import { ArrowLeft } from "@phosphor-icons/react";
import { forgotPasswordSchema } from "@reactive-resume/dto";
import {
  Alert,
  AlertDescription,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@reactive-resume/ui";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useForgotPassword } from "@/client/services/auth";

type FormValues = z.infer<typeof forgotPasswordSchema>;

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { forgotPassword, loading } = useForgotPassword();

  const form = useForm<FormValues>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: { email: "" },
  });

  const onSubmit = async (data: FormValues) => {
    await forgotPassword(data);
    setSubmitted(true);
    form.reset();
  };

  if (submitted) {
    return (
      <div className="space-y-8">
        <Helmet>
          <title>
            {t`You've got mail!`} - {t`WorkingLife`}
          </title>
        </Helmet>

        <div className="space-y-4">
          <h2 className="text-2xl font-semibold tracking-tight text-black">{t`You've got mail!`}</h2>
          <Alert variant="success">
            <AlertDescription className="pt-0">
              {t`A password reset link should have been sent to your inbox, if an account existed with the email you provided.`}
            </AlertDescription>
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen flex-col justify-between">
      {/* Centering the forgot password section */}
      <div className="flex grow flex-col items-center justify-center space-y-8">
        <div className="space-y-1.5 ">
          <h2 className="text-2xl font-semibold tracking-tight text-black">{t`Forgot your password?`}</h2>
          <h6 className="text-sm font-medium text-[#667085]">
            {t`Please, enter the email address associated with your account and we will send you a link to reset a password.`}
          </h6>
        </div>

        <div className="w-full max-w-md">
          <Form {...form}>
            <form className="flex flex-col gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                name="email"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t`Email`}</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <img
                          src="/icon/mail.svg"
                          alt="Email Icon"
                          className="absolute left-2 top-1/2 -translate-y-1/2"
                        />
                        <Input
                          placeholder="abcdefg@workinglife.com"
                          className="pl-8 text-sm text-[#667085]"
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="mt-4 flex items-center gap-x-2">
                <Button
                  variant="link"
                  className="px-5 text-black"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowLeft size={14} className="mr-2" />
                  <span>{t`Back`}</span>
                </Button>

                <Button
                  borderRadius="rounded-md"
                  backgroundColor="bg-[#0182A9]"
                  type="submit"
                  textColor="text-white"
                  disabled={loading}
                  className="w-full"
                >
                  {t`Send a link`}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};
