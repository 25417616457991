import { Trans } from "@lingui/macro";
import React from "react";

const CareerLinesVertical: React.FC = () => {
  const lines = [
    {
      src: "/landing/timeline-1.png",
      imageWidth: "145px",
      label: <Trans>Resume builder</Trans>,
      description: (
        <Trans>
          <div> - truly free with gorgeous templates</div>
          <div>
            - all features and <b>unlimited</b> usage
          </div>
          <div> - download as PDF or share an online link</div>
        </Trans>
      ),
      height: "w-[50px]",
      positionInline: {
        right: "50%",
      },
      labelPosition: {
        top: "13%",
        right: "-1%",
      },
    },
    {
      src: "/landing/timeline-4.png",
      imageWidth: "131px",
      label: <Trans>Cover letter styler</Trans>,
      description: (
        <Trans>
          <div> - easy to use and beautiful</div>
          <div className="whitespace-nowrap">
            - free guide on how to write <b>authentic</b> cover letters
          </div>
          <div> - download as PDF or share an online link</div>
        </Trans>
      ),
      height: "w-[70px]",
      positionInline: {
        left: "30%",
      },
      labelPosition: {
        top: "35%",
        left: "47%",
      },
    },
    {
      src: "/landing/timeline-2.png",
      imageWidth: "143px",
      label: <Trans>Personality Snapshots</Trans>,
      description: (
        <Trans>
          - show genuine interest and human effort to stand out <br />
          - highly appreciated by hiring managers <br />- download as PDF or share an online link
        </Trans>
      ),
      height: "w-[59px]",
      positionInline: {
        left: "35%",
      },
      labelPosition: {
        top: "55%",
        right: "-27%",
      },
    },
    {
      src: "/landing/timeline-5.png",
      imageWidth: "131.916px",
      label: <Trans>Assessments</Trans>,
      description: (
        <Trans>
          - DISC personality test with free report <br />
          - Work Values test with free report <br />
          <div className="min-w-fit whitespace-nowrap">
            - option to buy extended test results at 123test.com
          </div>
        </Trans>
      ),
      height: "w-[92px]",
      positionInline: {
        left: "25%",
      },
      labelPosition: {
        top: "72%",
        left: "49%",
      },
    },
    {
      src: "/landing/timeline-3.png",
      imageWidth: "127px",
      label: <Trans>And more to come…</Trans>,
      description: (
        <Trans>
          - analyses tools and guides <br />
          - stories and news <br />- secure sharing of verified working life overviews
        </Trans>
      ),
      minImageWidth: {
        minWidth: "129px",
      },
      height: "w-[59px]",
      positionInline: {
        left: "35%",
      },
      labelPosition: {
        top: "92%",
        left: "46%",
      },
    },
  ];

  return (
    <div className="relative mx-auto flex h-[1120px] w-full max-w-[375px] items-center justify-center bg-white px-5">
      {/* Vertical Line */}
      <div className="absolute left-1/2 h-full border-l border-black"></div>

      {/* Horizontal Lines */}
      {lines.map((line, index) => (
        <div
          key={index}
          className={`absolute ${line.height} border-t border-black`}
          style={{
            top: `${(index + 1) * 20 - 15}%`,
            ...line.positionInline,
          }}
        />
      ))}

      {/* Points (Joints) on the Vertical Line */}
      {lines.map((_, index) => (
        <div
          key={index}
          className="absolute size-2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black"
          style={{ top: `${(index + 1) * 20 - 15}%`, left: "50%" }}
        />
      ))}

      {/* Labels for each line */}
      {lines.map((line, index) => {
        const titleUp = index === 0 || index === 2 || index === 4;
        return (
          <div
            key={index}
            className="absolute -translate-x-1/2 -translate-y-1/2"
            style={{ ...line.labelPosition }}
          >
            {!titleUp && (
              <p className="mb-2 ml-2 bg-white text-sm font-medium text-black">{line.label}</p>
            )}
            {!titleUp && <p className="ml-2 bg-white text-xs text-black">{line.description}</p>}
            <img
              src={line.src}
              alt={line.src}
              style={{
                ...line.minImageWidth,
              }}
              className="object-cover"
              width={line.imageWidth}
            />
            {titleUp && (
              <p className="mb-2 ml-1 bg-white text-sm font-medium text-black">{line.label}</p>
            )}
            {titleUp && (
              <p className="ml-1 text-nowrap bg-white text-xs text-black">{line.description}</p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CareerLinesVertical;
