import { Trans } from "@lingui/macro";
import React from "react";

const PrivacyPolicy = () => (
  <div className="mx-auto my-10 max-w-2xl rounded-lg bg-gray-100 p-6 shadow-md">
    <h1 className="mb-4 text-2xl font-bold text-black">
      <Trans>Privacy Policy</Trans>
    </h1>
    <p className="mb-2 text-gray-600">
      <Trans>Effective date: 2024-11-04</Trans>
    </p>
    <p className="mb-4 text-gray-600">
      <Trans>Updated on: 2024-11-04</Trans>
    </p>
    <p className="mb-4 text-black">
      {/* eslint-disable-next-line lingui/text-restrictions */}
      <Trans>
        This Privacy Policy explains the policies of Workinglife on the collection and use of the
        information we collect when you access https://workinglife.com (the "Service"). This Privacy
        Policy describes your privacy rights and how you are protected under privacy laws.
      </Trans>
    </p>
    <p className="mb-4 text-black">
      <Trans>
        By using our Service, you are consenting to the collection and use of your information in
        accordance with this Privacy Policy. Please do not access or use our Service if you do not
        consent to the collection and use of your information as outlined in this Privacy Policy.
        This Privacy Policy has been created with the help of{" "}
        <a
          target="_blank"
          href="https://cookie-script.com/privacy-policy-generator"
          rel="noreferrer"
          className="text-blue-600 hover:underline"
        >
          CookieScript Privacy Policy Generator
        </a>
        .
      </Trans>
    </p>
    <p className="mb-4 text-black">
      <Trans>
        Workinglife is authorized to modify this Privacy Policy at any time. This may occur without
        prior notice.
      </Trans>
    </p>
    <p className="mb-4 text-black">
      <Trans>
        Workinglife will post the revised Privacy Policy on the{" "}
        <a href="https://workinglife.com" className="text-blue-600 hover:underline">
          https://workinglife.com
        </a>{" "}
        website.
      </Trans>
    </p>

    <h3 className="mb-2 mt-6 text-xl font-semibold text-black">
      <Trans>Collection and Use of Your Personal Information</Trans>
    </h3>
    <h4 className="mb-2 text-lg font-medium text-black">
      <Trans>Information We Collect</Trans>
    </h4>
    <p className="mb-2 text-black">
      <Trans>
        When using our Service, you will be prompted to provide us with personal information used to
        contact or identify you. https://workinglife.com collects the following information:
      </Trans>
    </p>
    <ul className="mb-4 list-inside list-disc text-black">
      <li>
        <Trans>Usage Data</Trans>
      </li>
      <li>
        <Trans>Name</Trans>
      </li>
      <li>
        <Trans>Email</Trans>
      </li>
      <li>
        <Trans>Mobile Number</Trans>
      </li>
      <li>
        <Trans>Social Media Profile</Trans>
      </li>
      <li>
        <Trans>Date of Birth</Trans>
      </li>
      <li>
        <Trans>Work Address</Trans>
      </li>
      <li>
        <Trans>Home Address</Trans>
      </li>
      <li>
        <Trans>Payment Information</Trans>
      </li>
      <li>
        <Trans>Resume details</Trans>
      </li>
    </ul>

    <p className="mb-2 text-black">
      <Trans>Usage Data includes the following:</Trans>
    </p>
    <ul className="mb-4 list-inside list-disc text-black">
      <li>
        <Trans>Internet Protocol (IP) address of computers accessing the site</Trans>
      </li>
      <li>
        <Trans>Web page requests</Trans>
      </li>
      <li>
        <Trans>Referring web pages</Trans>
      </li>
      <li>
        <Trans>Browser used to access site</Trans>
      </li>
      <li>
        <Trans>Time and date of access</Trans>
      </li>
    </ul>

    <h4 className="mb-2 text-lg font-medium text-black">
      <Trans>How We Collect Information</Trans>
    </h4>
    <p className="mb-2">
      <Trans>
        https://workinglife.com collects and receives information from you in the following manner:
      </Trans>
    </p>
    <ul className="mb-4 list-inside list-disc text-black">
      <li>
        <Trans>
          When you fill a registration form or otherwise submit your personal information.
        </Trans>
      </li>
      <li>
        <Trans>When you interact with our Service.</Trans>
      </li>
      <li>
        <Trans>From external, public sources.</Trans>
      </li>
    </ul>
    <p className="mb-4 text-black">
      <Trans>
        Your information will be stored for up to 365 days after it is no longer required to provide
        you the services. Your information may be retained for longer periods for reporting or
        record-keeping in accordance with applicable laws. Information which does not identify you
        personally may be stored indefinitely.
      </Trans>
    </p>

    <h4 className="mb-2 text-lg font-medium text-black">
      <Trans>How We Use Your Information</Trans>
    </h4>
    <p className="mb-2">
      <Trans>https://workinglife.com may use your information for the following purposes:</Trans>
    </p>
    <ul className="mb-4 list-inside list-disc text-black">
      <li>
        <Trans>
          Providing and maintaining our Service, as well as monitoring the usage of our Service.
        </Trans>
      </li>
      <li>
        <Trans>
          For other purposes. Workinglife will use your information for data analysis to identify
          usage trends or determine the effectiveness of our marketing campaigns when reasonable. We
          will use your information to evaluate and improve our Service, products, services, and
          marketing efforts.
        </Trans>
      </li>
      <li>
        <Trans>
          Managing your account. Your Personal Data can enable access to multiple functions of our
          Service that are available to registered users.
        </Trans>
      </li>
      <li>
        <Trans>
          To contact you. Workinglife will contact you by email, phone, SMS, or another form of
          electronic communication related to the functions, products, services, or security updates
          when necessary or reasonable.
        </Trans>
      </li>
      <li>
        <Trans>
          To update you with news, general information, special offers, new services, and events.
        </Trans>
      </li>
      <li>
        <Trans>
          Marketing and promotional initiatives. Workinglife will use non-specific information
          gathered from you in order to improve our marketing efforts.
        </Trans>
      </li>
      <li>
        <Trans>
          Testimonials and customer feedback collection. If you share a testimonial or review about
          your experience using our Service, it will be shared or otherwise used on the website.
        </Trans>
      </li>
      <li>
        <Trans>
          Managing customer orders. Your email address, phone number, social media profiles, and
          other user account information will be used in order to manage orders placed through our
          Service.
        </Trans>
      </li>
      <li>
        <Trans>
          For the performance of a contract. Your Personal Data will assist with the development,
          undertaking, and compliance of a purchase contract for products or services you have
          purchased through our Service.
        </Trans>
      </li>
      <li>
        <Trans>
          Administration information. Your Personal Data will be used as part of the operation of
          our website Administration practices.
        </Trans>
      </li>
      <li>
        <Trans>
          Dispute resolution and site protection. Your information will be used in the instance of a
          legal dispute to resolve issues related to our Service.
        </Trans>
      </li>
      <li>
        <Trans>
          User to user comments. Your information, such as your screen name, personal image, or
          email address, will be in public view when posting user to user comments.
        </Trans>
      </li>
    </ul>

    <h4 className="mb-2 text-lg font-medium text-black">
      <Trans>Contact Us</Trans>
    </h4>
    <p className="mb-2 text-black">
      <Trans>For any questions, please contact us through the following methods:</Trans>
    </p>
    <p className="mb-2 text-black">
      <Trans>Name: Workinglife</Trans>
    </p>
    <p className="mb-2 text-black">
      <Trans>Address: Jonkerbosplein 52, 6534 AB, Nijmegen, The Netherlands</Trans>
    </p>
    <p className="mb-4 text-black">
      <Trans>
        Email:{" "}
        <a href="mailto:info@workinglife.com" className="text-blue-600 hover:underline">
          info@workinglife.com
        </a>
      </Trans>
    </p>
    <p>
      <Trans>
        Thank you for using our Service! Your privacy is important to us, and we appreciate your
        trust in us.
      </Trans>
    </p>
  </div>
);

export default PrivacyPolicy;
