import { Trans } from "@lingui/macro";
import React from "react";
import { useWindowSize } from "usehooks-ts";

import CareerLinesVertical from "./vertical-time-line";

const CareerLines: React.FC<{ width: number }> = ({ width }) => {
  const isTablet = width >= 640 && width < 1400; // Tailwind's md breakpoint

  // Define lines with tablet-specific styles
  const lines = [
    {
      src: "/landing/timeline-1.png",
      imageWidth: isTablet ? "220px" : "312px", // Adjusted for tablet
      label: <Trans>Resume builder</Trans>,
      description: (
        <Trans>
          <div> - truly free with gorgeous templates</div>
          <div>
            - all features and <b>unlimited</b> usage
          </div>
          <div> - download as PDF or share an online link</div>
        </Trans>
      ),
      height: "h-[290px]",
      positionInline: {
        top: "170px", // Adjusted for tablet
      },
      labelPosition: {
        left: isTablet ? "19%" : "17%", // Adjusted for tablet
        top: isTablet ? "30%" : "32%", // Adjusted for tablet
      },
    },
    {
      src: "/landing/timeline-4.png",
      imageWidth: isTablet ? "180px" : "203px", // Adjusted for tablet
      label: <Trans>Cover letter styler</Trans>,
      description: (
        <Trans>
          <div> - easy to use and beautiful</div>
          <div>
            - free guide on how to write <b>authentic</b> cover letters
          </div>
          <div> - download as PDF or share an online link</div>
        </Trans>
      ),
      height: "h-[330px]",
      positionInline: {
        bottom: "130px", // Adjusted for tablet
      },
      labelPosition: {
        left: isTablet ? "42%" : "37%", // Adjusted for tablet
        bottom: isTablet ? "-2%" : "-1%", // Adjusted for tablet
      },
    },
    {
      src: "/landing/timeline-2.png",
      imageWidth: isTablet ? "260px" : "312px", // Adjusted for tablet
      label: <Trans>Personality Snapshots</Trans>,
      description: (
        <Trans>
          - show genuine interest and human effort to stand out <br />
          - highly appreciated by hiring managers <br />- download as PDF or share an online link
        </Trans>
      ),
      height: "h-[120px]",
      positionInline: {
        top: "340px", // Adjusted for tablet
      },
      labelPosition: {
        left: isTablet ? "54%" : "58%", // Adjusted for tablet
        top: isTablet ? "31%" : "33%", // Adjusted for tablet
      },
    },
    {
      src: "/landing/timeline-5.png",
      imageWidth: isTablet ? "370px" : "413px", // Adjusted for tablet
      label: <Trans>Assessments</Trans>,
      description: (
        <Trans>
          - DISC personality test with free report <br />
          - Work Values test with free report <br />
          <div className="min-w-fit whitespace-nowrap">
            - option to buy extended test results at 123test.com
          </div>
        </Trans>
      ),
      height: "h-[82px]",
      positionInline: {
        bottom: "380px", // Adjusted for tablet
      },
      labelPosition: {
        left: isTablet ? "73%" : "77%", // Adjusted for tablet
        bottom: isTablet ? "1%" : "3%", // Adjusted for tablet
      },
    },
    {
      src: "/landing/timeline-3.png",
      imageWidth: isTablet ? "220px" : "238px", // Adjusted for tablet
      label: <Trans>And more to come…</Trans>,
      description: (
        <Trans>
          - analyses tools and guides <br />
          - stories and news <br />- secure sharing of verified working life overviews
        </Trans>
      ),
      minImageWidth: {
        minWidth: "238px",
      },
      height: "h-[243px]",
      positionInline: {
        top: "220px", // Adjusted for tablet
      },
      labelPosition: {
        left: isTablet ? "85%" : "90%", // Adjusted for tablet
        top: isTablet ? "33%" : "35%", // Adjusted for tablet
      },
    },
  ];

  return (
    <div>
      <div>
        <h1 className="mt-10 text-center text-3xl font-black text-[#111928] md:text-6xl">
          <Trans>
            Tools and{" "}
            <span className="bg-[#DB7B03] bg-clip-text text-transparent">Key Features</span>{" "}
          </Trans>
        </h1>
        <p className="mt-4 text-center text-lg text-[#3F444F]">
          <Trans>
            Land on top of the application pile with authentic and beautiful documents. It's easy,
            free, beautiful and private.
          </Trans>
        </p>
      </div>
      <div className="relative mx-auto flex h-[920px] w-full max-w-7xl items-center justify-center bg-white px-5">
        {/* Horizontal Line */}
        <div className="absolute top-1/2 w-full border-t border-black"></div>

        {/* Vertical Lines */}
        {lines.map((line, index) => (
          <div
            key={index}
            className={`absolute ${line.height} border-l border-black`}
            style={{
              top: "50%",
              left:
                index === lines.length - 1
                  ? `${(index + 1) * 20 - 20}%`
                  : `${(index + 1) * 20 - 16}%`,
              ...line.positionInline, // Move the last point more to the left
            }}
          />
        ))}

        {/* Points (Joints) on the Horizontal Line */}
        {lines.map((_, index) => (
          <div
            key={index}
            className="absolute size-3 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black"
            style={{
              top: "50%",
              left:
                index === lines.length - 1
                  ? `${(index + 1) * 20 - 20}%`
                  : `${(index + 1) * 20 - 16}%`, // Move the last point more to the left
            }}
          />
        ))}

        {/* Labels for each line */}
        {lines.map((line, index) => {
          const titleUp = index === 0 || index === 2 || index === 4;
          return (
            <div
              key={index}
              className="absolute -translate-x-1/2 -translate-y-1/2"
              style={{ ...line.labelPosition }}
            >
              {!titleUp && (
                <p className="mb-2 ml-3 bg-white text-lg font-medium text-black">{line.label}</p>
              )}
              {!titleUp && (
                <p className="max-w-s ml-3 bg-white text-sm text-black">{line.description}</p>
              )}
              <img
                src={line.src}
                alt={line.src}
                style={{
                  ...line.minImageWidth,
                }}
                className="object-cover"
                width={line.imageWidth}
              />
              {titleUp && (
                <p className="mb-2 ml-3 bg-white text-lg font-medium text-black">{line.label}</p>
              )}
              {titleUp && (
                <p className="max-w-s ml-3 bg-white text-sm text-black">{line.description}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TimeLineSection = () => {
  const { width } = useWindowSize();
  const isMobile = width < 640;
  return (
    <div className="bg-white">
      {!isMobile && <CareerLines width={width} />}
      {isMobile && <CareerLinesVertical />}
    </div>
  );
};

export default TimeLineSection;
