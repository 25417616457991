// import { Trans } from "@lingui/macro";
import React from "react";
import { useLocation } from "react-router-dom";

// import Button from "./_components/buttons/button-kit";
import FAQSection from "./_components/faq-section";
// import CareerJourneySection from "./_components/features-section";
import Footer from "./_components/footer";
import HeaderSection from "./_components/header";
// import HighlightedInformationSection from "./_components/highlited-info-section";
import ModalComponent from "./_components/modal";
import NavBar from "./_components/nav-bar";
// import OurFeaturesSection from "./_components/our-key-features";
// import TemplateSection from "./_components/templates-section";
import TestimonialsSection from "./_components/testimonials-section";
import TimeLineSection from "./_components/timeline-section";
// import WhyWorkinglifeSection from "./_components/why-workinlife";
import AboutUsSection from "./about-us/about-us";
import CompanyValuesSection from "./about-us/company-values";
import TestimonialsSlider from "./about-us/testimonials";
import PrivacyPolicy from "./privacy/page";
import TermsAndConditions from "./terms/page";

const LandingPage = () => {
  const location = useLocation(); // Access current location

  const pathname = location.pathname;

  const segments = pathname.split("/");
  const isAboutUs = segments.includes("about-us");
  const isPrivacy = segments.includes("privacy-policy");
  const isTerms = segments.includes("terms-and-conditions");

  return (
    <div className="bg-white font-sans">
      <NavBar></NavBar>
      <ModalComponent />
      {isPrivacy && <PrivacyPolicy />}
      {isTerms && <TermsAndConditions />}
      {/* Header Section */}
      {isAboutUs && !isPrivacy && !isTerms && (
        <>
          <AboutUsSection />
          <CompanyValuesSection />
          <TestimonialsSlider></TestimonialsSlider>
        </>
      )}
      {!isAboutUs && !isPrivacy && !isTerms && (
        <>
          <HeaderSection />
          <TimeLineSection />
          {/* <CareerJourneySection /> */}

          {/* <TemplateSection /> */}

          {/* Why Workinglife Section */}
          {/* <WhyWorkinglifeSection /> */}

          {/* <HighlightedInformationSection /> */}

          {/* Features Section */}
          {/* <OurFeaturesSection /> */}

          <TestimonialsSection />
        </>
      )}

      {!isAboutUs && !isPrivacy && !isTerms && <FAQSection isAboutUs={isAboutUs} />}

      {/* Free Trial Section */}
      {/* {!isAboutUs && (
        <section className="bg-white p-8 text-center">
          <h2 className="text-2xl font-bold text-black">
            <Trans>Start your free trial today</Trans>
          </h2>
          <p className="mt-4 text-black">
            <Trans>Try Workinglife for 30 days. No credit card required.</Trans>
          </p>
          <div className="mt-6 space-y-4 md:space-x-4">
            <Button
              border
              onClick={() => {
                console.log();
              }}
            >
              <Trans>Read More</Trans>
            </Button>
            <Button
              bgColor="bg-[#0182A9]"
              textColor="text-white"
              onClick={() => {
                console.log();
              }}
            >
              <Trans>Free trial for 30 days</Trans>
            </Button>
          </div>
        </section>
      )} */}

      {/* Footer Section */}
      <Footer></Footer>
    </div>
  );
};

export default LandingPage;
