/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import { cn } from "@reactive-resume/utils";

type Props = {
  className?: string;
};

export const Copyright = ({ className }: Props) => (
  <div
    className={cn(
      "prose prose-sm prose-zinc flex max-w-none flex-col gap-y-1 text-xs opacity-40 dark:prose-invert",
      className,
    )}
  >
    <span className="mt-4">
      {t`WorkingLife`} {"v" + appVersion + appEnvironment.charAt(0)}
      {appEnvironment !== "production" && (
        <>
          <br />
          {" Environment: " + appEnvironment} <br />
          {" ApiAssessUrl: " + appApiAssessUrl} <br />
          {" Api123testUrl: " + appApi123testUrl} <br />
        </>
      )}
    </span>
  </div>
);
