import { Slot } from "@radix-ui/react-slot";
import { cn } from "@reactive-resume/utils";
import type { VariantProps } from "class-variance-authority";
import { forwardRef } from "react";

// Extend buttonVariants to handle dynamic styles
import { buttonVariants } from "../variants/button";

export type ButtonProps = {
  asChild?: boolean;
  borderRadius?: string; // Tailwind class for border-radius (e.g., 'rounded-lg')
  backgroundColor?: string; // Tailwind class for background color (e.g., 'bg-blue-500')
  textColor?: string; // Tailwind class for text color (e.g., 'text-white')
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      borderRadius,
      backgroundColor,
      textColor,
      ...props
    },
    ref,
  ) => {
    const Component = asChild ? Slot : "button";

    return (
      <Component
        ref={ref}
        className={cn(
          buttonVariants({ variant, size, className }),
          borderRadius,
          backgroundColor,
          textColor,
        )}
        {...props}
      />
    );
  },
);

Button.displayName = "Button";
