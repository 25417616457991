import { Trans } from "@lingui/macro";

import { useAuthProviders } from "@/client/services/auth/providers";

export const SocialAuth = () => {
  const { providers } = useAuthProviders();

  if (!providers || providers.length === 0) return null;

  return (
    <div className="gap-4">
      {providers.includes("google") && (
        <a href="/api/auth/google" className="inline-block w-full">
          <button className="flex w-full items-center justify-center gap-2 rounded-md border border-[#D0D5DD] bg-white px-3 py-2 text-gray-800 shadow hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-200">
            <img src="/assets/google.svg" alt="Google Icon" className="size-4" />
            <Trans>Google</Trans>
          </button>
        </a>
      )}
    </div>
  );
};
