import { useQuery } from "@tanstack/react-query";

import { ASSESSMENT_CHECK_KEY } from "@/client/constants/query-keys"; // Create a constant for assessment check key
import { axios } from "@/client/libs/axios";

// types.ts
export type CheckAssessmentsResponse = {
  userId: string;
  hasTwoUnique: boolean;
  message: string;
};

// Fetch data from the /api/assessment/check endpoint
export const fetchAssessmentCheck = async (): Promise<CheckAssessmentsResponse> => {
  const response = await axios.get<CheckAssessmentsResponse>(`/assessment/check`); // Only type as CheckAssessmentsResponse
  return response.data; // Return the data directly
};

// Custom hook to use the check assessment API
export const useAssessmentCheck = () => {
  const {
    error,
    isLoading,
    data: isAssessmentComplete,
  } = useQuery<CheckAssessmentsResponse>({
    queryKey: [ASSESSMENT_CHECK_KEY],
    queryFn: fetchAssessmentCheck,
  });

  return { isAssessmentComplete, isLoading, error };
};
