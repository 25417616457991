import { AssessmentDto } from "@reactive-resume/dto";
import { InvalidateQueryFilters, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { axios } from "@/client/libs/axios";
import { queryClient } from "@/client/libs/query-client";

export const createAssessment = async (instrumentId: string) => {
  const response = await axios.post<
    AssessmentDto,
    AxiosResponse<AssessmentDto>,
    { instrumentId: string }
  >(
    "/assessment",
    { instrumentId }, // Send the dynamic parameter as part of the body
  );

  return response.data;
};

export const useCreateAssessment = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: createAssessmentFn,
  } = useMutation({
    mutationFn: (instrumentId: string) => createAssessment(instrumentId), // Pass instrumentId to the API function
    onSuccess: async (data) => {
      const url = `https://${appApiAssessUrl}/?cmd=init&token=${data.apiId}`;

      // Choose the URL based on instrumentId
      // if (data.instrumentId === "disc-en_us-2") {
      //   url = `https://www.123test.com/disc-personality-test/${data.apiId}`;
      // } else if (data.instrumentId === "work-values-en_us-2") {
      //   url = `https://www.123test.com/work-values-test/${data.apiId}`;
      // }

      window.open(url, "_self");
      // Assuming 'data.id' is available in the response for the created assessment
      queryClient.setQueryData<AssessmentDto>(["assessment", { id: data.id }], data);

      // Assuming there is a cache for assessments
      queryClient.setQueryData<AssessmentDto[]>(["assessments"], (cache) => {
        if (!cache) return [data];
        return [...cache, data];
      });

      await queryClient.invalidateQueries(["assessments"] as InvalidateQueryFilters); // Invalidate the assessments query
    },
  });

  return { createAssessment: createAssessmentFn, loading, error };
};
