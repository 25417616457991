import { useQuery } from "@tanstack/react-query";

import { ASSESSMENT_BASE_64 } from "@/client/constants/query-keys"; // Create a constant for assessment check key
import { axios } from "@/client/libs/axios";

// types.ts

// Fetch data from the /api/resume/convert-to-base64/image endpoint with a parameter
export const fetchBase64 = async (assessmentId: string | undefined) => {
  const imageUrl = `https://api26.123test.com/v2/report/${assessmentId}`; // Use the assessmentId to construct the URL
  const response = await axios.get(`/resume/convert-to-base64/image`, {
    params: { url: imageUrl }, // Pass the URL as a query parameter
  });
  return response.data.base64Str; // Return the data directly
};

// Custom hook to use the check assessment API
export const useBase64 = (assessmentId: string | undefined) => {
  const {
    error,
    isLoading,
    data: imgSrc,
  } = useQuery({
    queryKey: [ASSESSMENT_BASE_64, assessmentId], // Include assessmentId in the query key
    queryFn: () => fetchBase64(assessmentId), // Pass assessmentId to the fetch function
    enabled: !!assessmentId, // Only run the query if assessmentId is provided
  });

  return { imgSrc, isLoading, error };
};
