import { t } from "@lingui/macro";
import { basicsSchema } from "@reactive-resume/schema";
import { Input, Label } from "@reactive-resume/ui";

import { useResumeStore } from "@/client/stores/resume";

import { CustomFieldsSection } from "./custom/section";
import { PictureSection } from "./picture/section";
import { getSectionIcon } from "./shared/section-icon";
import { URLInput } from "./shared/url-input";

export const BasicsSection = ({
  isCoverLetterPage,
  isPersonalitySnapshotPage,
}: {
  isCoverLetterPage: boolean;
  isPersonalitySnapshotPage: boolean;
}) => {
  const isResumeLocked = useResumeStore((state) => state.resume).locked;
  const setValue = useResumeStore((state) => state.setValue);
  const basics = useResumeStore((state) => state.resume.data.basics);

  return (
    <section id="basics" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon("basics")}
          <h2 className="line-clamp-1 text-3xl font-bold">{t`Basics`}</h2>
        </div>
      </header>

      <main className="grid gap-4 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <PictureSection />
        </div>

        <div className="space-y-1.5 sm:col-span-2">
          <Label htmlFor="basics.name">{t`Full Name`}</Label>
          <Input
            id="basics.name"
            value={basics.name}
            disabled={isResumeLocked}
            hasError={!basicsSchema.pick({ name: true }).safeParse({ name: basics.name }).success}
            onChange={(event) => {
              setValue("basics.name", event.target.value);
            }}
          />
        </div>

        {!isPersonalitySnapshotPage && (
          <div className="space-y-1.5 sm:col-span-2">
            <Label htmlFor="basics.headline">{t`Headline`}</Label>
            <Input
              id="basics.headline"
              value={basics.headline}
              disabled={isResumeLocked}
              onChange={(event) => {
                setValue("basics.headline", event.target.value);
              }}
            />
          </div>
        )}

        <div className="space-y-1.5">
          <Label htmlFor="basics.email">{t`Email`}</Label>
          <Input
            id="basics.email"
            placeholder="john.doe@example.com"
            value={basics.email}
            disabled={isResumeLocked}
            hasError={
              !basicsSchema.pick({ email: true }).safeParse({ email: basics.email }).success
            }
            onChange={(event) => {
              setValue("basics.email", event.target.value);
            }}
          />
        </div>

        <div className="space-y-1.5">
          <Label htmlFor="basics.url">{t`Website`}</Label>
          <URLInput
            id="basics.url"
            value={basics.url}
            disabled={isResumeLocked}
            placeholder="https://example.com"
            onChange={(value) => {
              setValue("basics.url", value);
            }}
          />
        </div>

        <div className="space-y-1.5">
          <Label htmlFor="basics.phone">{t`Phone`}</Label>
          <Input
            id="basics.phone"
            placeholder="+1 (123) 4567 7890"
            value={basics.phone}
            disabled={isResumeLocked}
            onChange={(event) => {
              setValue("basics.phone", event.target.value);
            }}
          />
        </div>

        <div className="space-y-1.5">
          <Label htmlFor="basics.location">{t`Location`}</Label>
          <Input
            id="basics.location"
            value={basics.location}
            disabled={isResumeLocked}
            onChange={(event) => {
              setValue("basics.location", event.target.value);
            }}
          />
        </div>

        {isPersonalitySnapshotPage && (
          <>
            <div>
              <Label htmlFor="basics.jobTitle">{t`Applying for:`}</Label>
            </div>
            <div className="flex space-x-4 sm:col-span-2">
              <div className="flex-1 space-y-1.5">
                <Label htmlFor="basics.jobTitle">{t`Job title`}</Label>
                <Input
                  id="basics.jobTitle"
                  value={basics.jobTitle}
                  disabled={isResumeLocked}
                  onChange={(event) => {
                    setValue("basics.jobTitle", event.target.value);
                  }}
                />
              </div>
              <div className="flex-1 space-y-1.5">
                <Label htmlFor="basics.companyName">{t`Company Name`}</Label>
                <Input
                  id="basics.companyName"
                  value={basics.companyName}
                  disabled={isResumeLocked}
                  onChange={(event) => {
                    setValue("basics.companyName", event.target.value);
                  }}
                />
              </div>
            </div>
          </>
        )}

        {isCoverLetterPage && (
          <>
            <div className="space-y-1.5">
              <Label htmlFor="basics.jobTitle">{t`Job title`}</Label>
              <Input
                id="basics.jobTitle"
                value={basics.jobTitle}
                disabled={isResumeLocked}
                onChange={(event) => {
                  setValue("basics.jobTitle", event.target.value);
                }}
              />
            </div>
            <div className="space-y-1.5">
              <Label htmlFor="basics.companyName">{t`Company Name`}</Label>
              <Input
                id="basics.companyName"
                value={basics.companyName}
                disabled={isResumeLocked}
                onChange={(event) => {
                  setValue("basics.companyName", event.target.value);
                }}
              />
            </div>
            <div className="space-y-1.5">
              <Label htmlFor="basics.companyAddress">{t`Company Address`}</Label>
              <Input
                id="basics.companyAddress"
                value={basics.companyAddress}
                disabled={isResumeLocked}
                onChange={(event) => {
                  setValue("basics.companyAddress", event.target.value);
                }}
              />
            </div>
            <div className="space-y-1.5">
              <Label htmlFor="basics.contactPerson">{t`Contact Person`}</Label>
              <Input
                id="basics.location"
                value={basics.contactPerson}
                disabled={isResumeLocked}
                onChange={(event) => {
                  setValue("basics.contactPerson", event.target.value);
                }}
              />
            </div>
          </>
        )}

        <CustomFieldsSection className="sm:col-span-2" />
      </main>
      <div className="">
        <Input
          id="basics.testDate"
          value={basics.testDate}
          disabled={isResumeLocked}
          style={{ display: "none" }}
          onChange={(event) => {
            setValue("basics.testDate", event.target.value);
          }}
        />
      </div>
    </section>
  );
};
