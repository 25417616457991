import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

import { useAssessmentCheck } from "@/client/services/assessment/check";

export const TakeAssessment = () => {
  const { isAssessmentComplete } = useAssessmentCheck();
  const hasTwoUnique = isAssessmentComplete?.hasTwoUnique;

  if (hasTwoUnique) return <div></div>;
  return (
    <div className="mb-8">
      <h3 className="text-2xl font-bold">
        <Trans>Take assessments first</Trans>
      </h3>
      <p className="mb-8 mt-4 text-gray-600">
        <Trans>
          To generate personality snapshots, you must have completed the required assessments first.
          These are a DISC personality assessment test and a Work values assessment test. Take them
          when you have enough time and are in a quiet and private setting. Each takes about ten to
          twelve minutes on average.
        </Trans>
      </p>
      <Link to="/dashboard/assessments" className="mb-8 text-gray-800">
        <Trans>
          Go to <u>assessments</u> now to read more and to take the assessments.
        </Trans>
      </Link>
    </div>
  );
};
