/* eslint-disable lingui/no-unlocalized-strings */
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

const ModalComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("resume");

  const openModalWithTab = (tabId: string) => {
    setActiveTab(tabId);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Tab data array with id
  const tabData = [
    {
      id: "resume",
      dashboardUrl: "/dashboard/resumes",
      name: <Trans>Resume</Trans>,
      title: <div className="my-5  font-black text-[#111928]">Free resume builder</div>,
      firstParagraph:
        "Crafting a standout resume has never been easier with our <strong>Resume Builder</strong>! Choose from <strong>gorgeous templates</strong> to showcase your skills and experience.",
      secondParagraph:
        "Enjoy <strong>unlimited access to all features</strong>, empowering you to customize your resume exactly how you want it. Our platform is private, secure, and incredibly <strong>user-friendly</strong>, ensuring a hassle-free experience from start to finish.",
      thirdParagraph:
        "Once you're done, download your polished resume as a <strong>PDF</strong> or share it instantly with employers using a convenient online link.",
      img: "/landing/resume-left-image.png",
    },
    {
      id: "cover-letters",
      dashboardUrl: "/dashboard/cover-letters",
      name: "Cover Letter",
      title: <div className="my-5  font-black text-[#111928]">Free cover letter styler</div>,
      firstParagraph:
        "Power up our job applications with our <strong>Free Cover Letter Styler</strong>! Enjoy <strong>real-time previews</strong> and a wide range of design options to create a cover letter that matches <strong>your style</strong> and stands out.",
      secondParagraph:
        "Get expert <strong>guidance on writing authentic, non-generated cover letters</strong> that reflect your unique voice and professional story. When ready, you can download it as a <strong>PDF</strong> or share it seamlessly via an online link.",
      thirdParagraph: "Start crafting your ideal cover letter today.",
      img: "/landing/coverletter-left-image.jpg",
    },
    {
      id: "personality-snapshots",
      dashboardUrl: "/dashboard/personality-snapshots",
      name: "Personality Snapshots",
      title: <div className="my-5  font-black text-[#111928]">Free Personality Snapshots</div>,
      firstParagraph:
        "Truly stand out in job applications with a unique <strong>snapshot of your personality</strong>!",
      secondParagraph:
        "Take our <strong>free assessment tests</strong> to uncover your personality traits and values, giving employers <strong>a great first glance</strong> of who you are beyond your resume and cover letter. This personalized insight helps you to set yourself apart and rise to the top of the application pile.",
      thirdParagraph:
        "Once complete, you can download your profile as a <strong>PDF</strong> or share it instantly with an online link. Elevate your applications and let your personality shine",
      img: "/landing/snapshot-left-image.jpg",
    },
  ];

  // Find active tab data based on the id
  const activeTabData = tabData.find((tab) => tab.id === activeTab);

  return (
    <div>
      {/* Three buttons fixed to the right side of the screen */}
      <button
        className="fixed right-0 top-1/3 z-50 mb-2 -translate-y-1/2 rounded-l-[18px] bg-gradient-to-br from-[#DB7B03] to-[#FFEFDA] p-[18px] text-white opacity-80 shadow-lg md:p-[36px]"
        onClick={() => {
          openModalWithTab("resume");
        }}
      >
        <img src="/landing/resume-icon.svg" alt="resume tab" width="18px" className="md:w-[36px]" />
      </button>
      <button
        className="fixed right-0 top-1/2 z-50 mb-2 -translate-y-1/2 rounded-l-[18px] bg-gradient-to-br from-[#DB7B03] to-[#FFEFDA] p-[18px] text-white opacity-80 shadow-lg md:p-[36px]"
        onClick={() => {
          openModalWithTab("cover-letters");
        }}
      >
        <img
          src="/landing/ph_envelope-bold.svg"
          alt="cover letter tab"
          width="18px"
          className="md:w-[36px]"
        />
      </button>
      <button
        className="fixed right-0 top-2/3 z-50 -translate-y-1/2 rounded-l-[18px] bg-gradient-to-br from-[#DB7B03] to-[#FFEFDA] p-[18px] text-white opacity-80 shadow-lg md:p-[36px]"
        onClick={() => {
          openModalWithTab("personality-snapshots");
        }}
      >
        <img
          src="/landing/ph_person-arms-spread-bold.svg"
          alt="personality snapshot tab"
          width="18px"
          className="md:w-[36px]"
        />
      </button>

      {/* Modal overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800"
          style={{ backgroundColor: "rgba(31, 41, 55, 0.5)" }}
          onClick={closeModal}
        >
          {/* Modal content */}
          <div
            className="relative flex max-w-4xl rounded-r-3xl bg-white shadow-lg"
            onClick={(e) => {
              e.stopPropagation();
            }} // Prevent closing on content click
          >
            {/* Left Side Image */}
            <div className="size-full max-md:hidden">
              <img
                src={activeTabData?.img}
                alt="resume builder"
                className="object-cover object-top"
              />
            </div>

            {/* Right Side Content */}
            <div className="flex flex-col justify-center p-8 pl-10">
              {/* Close Button */}
              <button
                className="absolute right-4 top-4 text-gray-600 hover:text-gray-900"
                onClick={closeModal}
              >
                <img src="/landing/basil_cross-solid.svg" alt="close" width="60px" />
              </button>

              {/* Horizontal Tab Switcher */}
              <div className="mb-4 flex max-w-fit justify-center self-start rounded-[12px] border border-[#B0B0B0]">
                {tabData.map((tab) => (
                  <button
                    key={tab.id} // Use id as the key
                    className={`px-[12px] py-[16px] ${
                      activeTab === tab.id ? "bg-[#0182A9] text-white" : "bg-white text-[#626262]"
                    } rounded-[12px] text-[16px] font-bold max-sm:px-[5px] max-sm:py-[8px] max-sm:text-[12px]`}
                    onClick={() => {
                      setActiveTab(tab.id); // Set active tab by id
                    }}
                  >
                    {tab.name} {/* Keep the name for display */}
                  </button>
                ))}
              </div>

              {/* Content for active tab */}
              <div className="mb-4" style={{ height: "250px", overflowY: "auto" }}>
                <h2 className="mb-5 text-[30px] font-semibold max-sm:text-xl">
                  {activeTabData?.title}
                </h2>
                <p dangerouslySetInnerHTML={{ __html: activeTabData?.firstParagraph ?? "" }}></p>
                <br />
                <p dangerouslySetInnerHTML={{ __html: activeTabData?.secondParagraph ?? "" }}></p>
                <br />
                <p dangerouslySetInnerHTML={{ __html: activeTabData?.thirdParagraph ?? "" }}></p>
              </div>

              {/* Start Building Button */}
              <button className="mt-4 max-w-fit rounded bg-[#0182A9] px-[20px] py-[6px] text-[16px] text-white shadow-lg">
                <a href={`${activeTabData?.dashboardUrl}`}>
                  {" "}
                  <Trans>Signup now</Trans>
                </a>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalComponent;
