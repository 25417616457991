import { AssessmentDto } from "@reactive-resume/dto";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ASSESSMENTS_KEY } from "@/client/constants/query-keys"; // Create a constant for assessments key
import { axios } from "@/client/libs/axios";

import { AssessmentTypes } from "./constants/main";

// Fetch all assessments from the API
export const fetchAssessmentsMin = async () => {
  const response = await axios.get<AssessmentDto[], AxiosResponse<AssessmentDto[]>>(`/assessment`);
  return response.data;
};

// Custom hook to use assessments
export const useActiveAssessmentsMin = () => {
  const {
    error,
    isLoading,
    data: assessments,
  } = useQuery({
    queryKey: [ASSESSMENTS_KEY],
    queryFn: fetchAssessmentsMin,
    // retryOnMount: true,
  });

  // Find the assessments for DISC and Work Values
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const discAssessment = assessments?.find(
    (a) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      a.instrumentId === AssessmentTypes.DISC &&
      a.isActive === "active" &&
      (a.status === "active" || a.status === "paid"),
  );
  const workValuesAssessment = assessments?.find(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    (a) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      a.instrumentId === AssessmentTypes.WORK_VALUES &&
      a.isActive === "active" &&
      (a.status === "active" || a.status === "paid"),
  );

  return { assessments, discAssessment, workValuesAssessment, isLoading, error };
};
