/* eslint-disable lingui/no-unlocalized-strings */
import { t, Trans } from "@lingui/macro";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
export const DashboardStartPage = () => {
  // Fetch dashboard data (e.g., stats, recent activities)
  useEffect(() => {
    // Fetch data here if needed
  }, []);

  return (
    <div className="space-y-6 p-6">
      <Helmet>
        <title>
          {t`Start`} - {t`WorkingLife`}
        </title>
      </Helmet>

      {/* Welcome Message */}
      <section>
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          className="mb-6 text-4xl font-bold tracking-tight"
        >
          {t`Start`}
        </motion.h1>
        <p className="max-w-xl text-gray-600">
          <Trans>Welcome to your dashboard</Trans>
        </p>
        <div className="mb-20 mt-10">
          <h2 className="mb-5 text-[30px] font-semibold max-sm:text-xl">Resumes</h2>
          <iframe
            className="max-w-sm border-none"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/uIxb7gS95WI?si=bgaeYwJBMy7PMCti"
            title="YouTube video player"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </div>
        <div className="mb-20">
          <h2 className="mb-5 text-[30px] font-semibold max-sm:text-xl">Assessments</h2>
          <iframe
            className="max-w-sm border-none"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/abAwBvqZVhw?si=lq47XUi6s43uVWil"
            title="YouTube video player"
            allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </div>
        <div className="mb-20">
          <h2 className="mb-5 text-[30px] font-semibold max-sm:text-xl">Cover letters</h2>
          <iframe
            className="max-w-sm border-none"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/nPaR3BEeMAI?si=TPuCdxL8bsY-kwXP"
            title="YouTube video player"
            allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </div>
        <div className="mb-20">
          <h2 className="mb-5 text-[30px] font-semibold max-sm:text-xl">Personality Snapshot</h2>
          <iframe
            className="max-w-sm border-none"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/UTr0VUuWlS4?si=wL3uUx7gtXAkIOmx"
            title="YouTube video player"
            allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </div>
      </section>
    </div>
  );
};
