import { Trans } from "@lingui/macro";
import React from "react";

const CompanyValuesSection = () => {
  return (
    <div className="mr-10 bg-white">
      <header className="ml-3 grid grid-cols-1 bg-white p-8 text-left md:ml-0 md:grid-cols-2">
        {/* Left content: Image */}
        <div className="mt-8 hidden items-center justify-center md:block lg:flex">
          <img src="/landing/values.png" alt="Landing" width="448px" height="450px" />
        </div>
        {/*  Right content: Text and buttons */}
        <div className="flex max-w-xl flex-col justify-center md:ml-24">
          <h1 className="text-3xl font-black md:text-6xl">
            <Trans>
              <span className="bg-[#DB7B03] bg-clip-text text-transparent">Company Values</span>{" "}
            </Trans>
          </h1>
          <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
            <Trans>
              We value transparency, put quality before quantity and foster a friendly cooperative
              company culture.
            </Trans>
          </div>
          <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
            <Trans>
              By using a wide range open source tools and software, plus our experience, we are able
              to provide tooling 100% for free forever.
            </Trans>
          </div>
          <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
            <Trans>
              At 123test.com you can buy extended reports of assessment tests if you like.
            </Trans>
          </div>
          <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
            <Trans>
              We hope to help you out at many points in your career, standing with you from
              graduation to retirement in your{" "}
              <span className="text-[#3F444F]">
                <b>workinglife</b>
              </span>
              .
            </Trans>
          </div>
        </div>
      </header>
    </div>
  );
};

export default CompanyValuesSection;
