/* eslint-disable  lingui/text-restrictions*/
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

const FAQSection = ({ isAboutUs }: { isAboutUs: boolean }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const faqs = [
    {
      question: <Trans>What is a truly free resume builder?</Trans>,
      answer: (
        <Trans>
          Our resume builder is 100% free, with no hidden fees or limitations. You have access to
          all features and templates, and you can download your resume as a PDF without any
          watermarks or locked features. For workinglife.com, the resume builder isn't a revenue
          stream but a helpful tool to attract visitors to our website.
        </Trans>
      ),
    },
    {
      question: <Trans>Why a cover letter "styler" and not a cover letter generator?</Trans>,
      answer: (
        <Trans>
          We believe that a cover letter should reflect your unique personality and effort. Simply
          pressing an "auto-generate" button doesn't achieve that. Hiring managers often see too
          many generic, "perfectly generated" cover letters, which don't stand out. While AI can
          assist, creating an authentic, thoughtfully crafted cover letter helps you get to the top
          of the application pile. Automated cover letters and "auto-apply" applications may even
          lower your chance of getting hired.
        </Trans>
      ),
    },
    {
      question: (
        <Trans>
          Do hiring managers really appreciate a Personality Snapshot along with my resume?
        </Trans>
      ),
      answer: (
        <Trans>
          Yes, definitely! Including a Personality Snapshot shows you're genuinely interested in the
          job and willing to put in human effort. This snapshot offers a quick glimpse of who you
          are, backed by objective assessment tests, which hiring managers notice. Some employers
          even request a Personality Snapshot to get an initial sense of who you are beyond your
          resume.
        </Trans>
      ),
    },
    {
      question: <Trans>What career assessment tests do you offer?</Trans>,
      answer: (
        <Trans>
          We currently offer two free assessments: a DISC personality test and a Work Values test.
          The results from these tests can be displayed on your Personality Snapshot, and you'll
          also receive free standalone results for personal insight. We may add more assessments and
          templates in the future to give you even more personalization options.
        </Trans>
      ),
    },
    {
      question: <Trans>How do you make money?</Trans>,
      answer: (
        <Trans>
          Workinglife is powered by 123test.com, which also provides all of our career assessment
          tests. While our assessments offer free results, we may also provide the option to
          purchase extended versions of these results for a modest price. Since we rely on
          open-source software and a streamlined operation, we don't need many people to purchase
          upgrades to sustain Workinglife. All of our core tools will remain free forever.
        </Trans>
      ),
    },
  ];

  const toggleFAQ = (index: any) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section
      className={`p-8 ${
        isAboutUs
          ? "bg-gradient-to-r from-[#CFEAFA] to-[#0182A9]"
          : "bg-gradient-to-r from-[#FFEFDA] to-[#DB7B03]"
      }`}
      id="faq"
    >
      <div className="mx-auto gap-8 rounded-2xl bg-white p-8 px-6 md:px-32 lg:w-[1200px]">
        <div className="mx-auto lg:w-[695px]">
          <h2 className="mb-10 text-center text-3xl font-bold text-[#111928]">
            <Trans>Frequently Asked Questions</Trans>
          </h2>
          {faqs.map((faq, index) => (
            <div key={index} className="mt-4">
              <div
                className="flex cursor-pointer items-center justify-between text-[#111928]"
                onClick={() => {
                  toggleFAQ(index);
                }}
              >
                <h3 className="flex-1 text-sm font-semibold">{faq.question}</h3>
                {/* Render arrow icon based on the expanded state */}
                {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
                <span className="text-gray-600 ">
                  {expandedIndex === index ? (
                    <i className="ph ph-caret-up size-6" /> // Caret up icon
                  ) : (
                    <i className="ph ph-caret-down size-6" /> // Caret down icon
                  )}
                </span>
              </div>
              <div className="mt-2 border-b border-gray-300" /> {/* Bottom border line */}
              {expandedIndex === index && (
                <p className="mt-2 text-xs text-[#111928]">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
