import { zodResolver } from "@hookform/resolvers/zod";
import { t, Trans } from "@lingui/macro";
import { registerSchema } from "@reactive-resume/dto";
import { usePasswordToggle } from "@reactive-resume/hooks";
import {
  Alert,
  AlertTitle,
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";

import { useRegister } from "@/client/services/auth";
import { useFeatureFlags } from "@/client/services/feature";

type FormValues = z.infer<typeof registerSchema>;

export const RegisterPage = () => {
  const navigate = useNavigate();
  const { flags } = useFeatureFlags();
  const { register, loading } = useRegister();

  const formRef = useRef<HTMLFormElement>(null);
  usePasswordToggle(formRef);

  const form = useForm<FormValues>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      name: "",
      username: "",
      email: "",
      password: "",
      locale: "en-US",
    },
  });

  const onSubmit = async (data: FormValues) => {
    try {
      await register(data);
      navigate("/auth/verify-email");
    } catch {
      form.reset();
    }
  };

  return (
    <div className="space-y-4">
      <Helmet>
        <title>
          {t`Create a new account`} - {t`WorkingLife`}
        </title>
      </Helmet>

      <div className="mt-6 space-y-1">
        <h2 className="text-lg font-semibold tracking-tight text-black">{t`Create a new account`}</h2>
        <p className="text-xs font-medium text-[#667085]">
          <Trans>Please fill your information below</Trans>
        </p>
      </div>

      {flags.isSignupsDisabled && (
        <Alert variant="error">
          <AlertTitle>{t`Signups are currently disabled by the administrator.`}</AlertTitle>
        </Alert>
      )}

      <div className={cn(flags.isSignupsDisabled && "pointer-events-none select-none blur-sm")}>
        <Form {...form}>
          <form
            ref={formRef}
            className="flex flex-col gap-y-1.5"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              name="name"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs text-black">{t`Name`}</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <img
                        src="/icon/icon-park-outline_edit-name.svg"
                        alt="Name Icon"
                        className="absolute left-2 top-1/2 size-4 -translate-y-1/2"
                      />
                      <Input
                        placeholder={t({ message: "John Hendricks", context: "Localized name" })}
                        className="pl-8 text-xs text-[#667085]"
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="username"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs text-black">{t`Username`}</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <img
                        src="/icon/icon-park-outline_edit-name.svg"
                        alt="Username Icon"
                        className="absolute left-2 top-1/2 size-4 -translate-y-1/2"
                      />
                      <Input
                        placeholder="john_h"
                        className="pl-8 text-xs text-[#667085]"
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs text-black">{t`Email`}</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <img
                        src="/icon/mail.svg"
                        alt="Email Icon"
                        className="absolute left-2 top-1/2 size-4 -translate-y-1/2"
                      />
                      <Input
                        placeholder="abcdefg@workinglife.com"
                        className="pl-8 text-xs text-[#667085]"
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="password"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs text-black">{t`Password`}</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <img
                        src="/icon/mdi_password-outline.svg"
                        alt="Password Icon"
                        className="absolute left-2 top-1/2 size-4 -translate-y-1/2"
                      />
                      <Input
                        type="password"
                        placeholder="*********"
                        className="pl-8 text-xs text-[#667085]"
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormDescription className="text-xs">
                    <Trans>
                      Hold <code className="text-xs font-bold">Ctrl</code> to display your password
                      temporarily.
                    </Trans>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              borderRadius="rounded-sm"
              backgroundColor="bg-[#0182A9]"
              disabled={loading}
              className="mt-2 w-full text-xs"
            >
              {t`Sign up`}
            </Button>
            <img src="/assets/grey-line.svg" alt="Divider line" className="my-3 w-full" />
            <h6 className="text-xs">
              <span className="px-1 text-xs font-medium text-[#475467]">{t`Already have an account?`}</span>

              <Button asChild variant="link" className="text-xs">
                <Link
                  to="/auth/login"
                  style={{
                    color: "#0182A9",
                  }}
                >
                  {t`Login`}
                </Link>
              </Button>
            </h6>
          </form>
        </Form>
      </div>
    </div>
  );
};
