import { useLocation } from "react-router-dom";

const useRouteImage = () => {
  const location = useLocation();
  let imgSrc = "";

  // Determine the image source based on the current route
  if (location.pathname.includes("login")) {
    imgSrc = "/assets/images/login.png"; // Replace with your actual login image path
  } else if (location.pathname.includes("register")) {
    imgSrc = "/assets/images/signup-new.png"; // Replace with your actual register image path
  } else if (location.pathname.includes("forgot-password")) {
    imgSrc = "/assets/images/forgot.png"; // Replace with your actual forgot password image path
  } else {
    imgSrc = "/assets/images/forgot.png"; // Replace with your default image path if needed
  }

  return imgSrc;
};

export default useRouteImage;
